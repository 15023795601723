import React, { useEffect, useState } from 'react';
import './experienceTimeline.scss';

function ExperienceTimeline(props) {
  const NS = 'http://www.w3.org/2000/svg';
  const LINE_DEPTH = 115;
  const MONTH_OFFSET = 6;
  const LEVEL_TWO = 35;
  const LEVEL_ONE = 90;
  const COLORS = ['#5899DA', '#E8743B', '#945ECF', '#19A979', '#ED4A7B', '#13A4B4', '#525DF4', '#BF399E'];

  const getMonthWidth = () => {
    if (window.innerWidth < 576) {
      var widthCalc = Math.ceil(window.innerWidth / 60);
      return widthCalc >= 7 ? widthCalc : 7;
    } else if (window.innerWidth < 768) {
      return 10;
    } else if (window.innerWidth < 992) {
      return 13;
    } else if (window.innerWidth < 1200) {
      return 16;
    } else if (window.innerWidth >= 1200) {
      return 18;
    }
  }

  let { activeIndex, changeActiveIndex, jobs } = props;
  let [monthWidth, setMonthWidth] = useState(getMonthWidth());
  let xOffset = 6 * monthWidth;

  window.addEventListener('resize', () => {
    setMonthWidth(getMonthWidth());
  });

  useEffect(() => {
    createTimeline();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, monthWidth])

  function createTimeline() {
    var svg = document.getElementById('canvas');

    // Clear svg
    while (svg.lastElementChild) {
      svg.removeChild(svg.lastElementChild);
    }

    var jobData = jobs[activeIndex].timeline;
    var box = document.createElementNS(NS, 'rect');
    box.setAttribute('x', (jobData.startMonths - MONTH_OFFSET) * monthWidth);
    box.setAttribute('y', jobData.level === 1 ? LEVEL_ONE : LEVEL_TWO);
    box.setAttribute('width', jobData.length * monthWidth);
    box.setAttribute('height', jobData.level === 1 ? 25 : 80);
    box.setAttribute('id', 'active-shadow');
    box.setAttribute('fill', 'rgba(0,0,0,.1)');
    svg.append(box);

    var completeElm = document.createElementNS(NS, 'g');
    completeElm.setAttribute('id', 'timeline-group');

    // Create Line
    completeElm.append(createTimelineLine(jobs.length));
    completeElm.append(createTicks());
    completeElm.append(createDates());

    // Add timeline events
    jobs.forEach((job, eventIndex) => {
      if(job.timeline.current) {
        let today = new Date();
        let month = today.getMonth() + 1;
        let year = today.getFullYear() - 1;
        job.timeline.length = (12 * (year - 2016) + month) - job.timeline.startMonths;
      }
      completeElm.append(createTimelineEvent(job.timeline, eventIndex));
    });

    svg.append(completeElm);
  };

  function createDates() {
    var datesGroup = document.createElementNS(NS, 'g');
    var startYear = 2017;
    for (var i = 0; i < 4; i++) {
      var txt = document.createElementNS(NS, 'text');
      txt.setAttribute('x', ((i * 12 * monthWidth) + xOffset));
      txt.setAttribute('y', LINE_DEPTH + 20);
      txt.setAttribute("fill", "#555555");

      var textNode = document.createTextNode("" + startYear);
      txt.appendChild(textNode);
      txt.setAttribute('class', 'tline-date')

      startYear++;
      datesGroup.append(txt);
    }

    return datesGroup;
  }

  function createTicks() {
    var tickGroup = document.createElementNS(NS, 'g');
    for (var i = 0; i < 4; i++) {
      var tick = document.createElementNS(NS, 'line');
      tick.setAttribute('x1', (i * (12 * monthWidth) + xOffset));
      tick.setAttribute('y1', LINE_DEPTH - 6);
      tick.setAttribute('x2', (i * (12 * monthWidth) + xOffset));
      tick.setAttribute('y2', LINE_DEPTH + 6);
      tick.setAttribute('class', 'line');
      tickGroup.append(tick);
    }
    return tickGroup;
  }

  function createTimelineLine(numOfEvents) {
    var newLine = document.createElementNS(NS, 'line');
    newLine.setAttribute('x1', '0');
    newLine.setAttribute('y1', LINE_DEPTH);
    newLine.setAttribute('x2', 9 * 12 * monthWidth);
    newLine.setAttribute('y2', LINE_DEPTH);
    newLine.setAttribute('class', 'line');
    return newLine;
  }

  function createPostitionText(text, offset) {
    var txt = document.createElementNS(NS, 'text');
    txt.setAttribute('x', offset.x);
    txt.setAttribute('y', offset.y);
    txt.setAttribute("fill", "black");

    var textNode = document.createTextNode(text);
    txt.appendChild(textNode);
    txt.setAttribute('class', 'tline-position')
    return txt;
  }

  function createCompanyText(text, offset) {
    var txt = document.createElementNS(NS, 'text');
    txt.setAttribute('x', offset.x);
    txt.setAttribute('y', offset.y);
    txt.setAttribute("fill", "black");

    var textNode = document.createTextNode(text);
    txt.appendChild(textNode);
    txt.setAttribute('class', 'tline-company')
    return txt;
  }

  function createTimeBar(jobData, index) {
    var box = document.createElementNS(NS, 'rect');
    box.setAttribute('x', (jobData.startMonths - MONTH_OFFSET) * monthWidth);
    box.setAttribute('y', jobData.level === 1 ? LEVEL_ONE : LEVEL_TWO);
    box.setAttribute('width', jobData.length * monthWidth);
    box.setAttribute('class', 'box');
    box.setAttribute('fill', COLORS[index]);
    return box;
  }

  function createArea(jobData, index) {
    var box = document.createElementNS(NS, 'rect');
    box.setAttribute('x', (jobData.startMonths - MONTH_OFFSET) * monthWidth);
    box.setAttribute('y', jobData.level === 1 ? LEVEL_ONE - 30 : LEVEL_TWO - 30);
    box.setAttribute('width', jobData.length * monthWidth);
    box.setAttribute('class', 'area-box');

    box.jobIndex = index;
    box.onclick = (e) => { changeActiveIndex(e.target.jobIndex); }

    return box;
  }

  function createTimelineEvent(jobData, index) {
    var eventGroup = document.createElementNS(NS, 'g');

    eventGroup.append(createTimeBar(jobData, index));
    eventGroup.append(createPostitionText(jobData.title,
      {
        x: (jobData.startMonths - MONTH_OFFSET) * monthWidth,
        y: jobData.level === 1 ? LEVEL_ONE - 19 : LEVEL_TWO - 19
      }));
    eventGroup.append(createCompanyText(jobData.company,
      {
        x: (jobData.startMonths - MONTH_OFFSET) * monthWidth,
        y: jobData.level === 1 ? LEVEL_ONE - 4 : LEVEL_TWO - 4
      }));
    eventGroup.append(createArea(jobData, index));
    eventGroup.setAttribute('class', 'clickable-group');
    return eventGroup;
  }

  return (
    <div id="timeline-container">
      <svg id="canvas"></svg>
    </div>
  );
}

export default ExperienceTimeline;