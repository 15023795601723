import React, { useState } from 'react';
import ExperienceTimeline from '../../components/timeline/experienceTimeline';
import './experienceSection.scss';
import jobs from './jobData.json';

function ExperienceSection() {
  const [activeIndex, setActiveIndex] = useState(jobs.length - 1);

  var descItems = jobs[activeIndex].description.map((desc, i) => <div className="job-description-item" key={"job-desc-" + i}>{desc}<div className="job-item-line"></div></div>);

  return (
    <section id="work-exp" className="section">
      <div className="container">
        <div className="row title title-bottom" id="experience-section-title">
          <h2>Experience</h2>
        </div>

        <div className="row title title-bottom justify-content-center" style={{ minHeight: '225px' }}>
          <div className="col-lg-10" id="selected-exp">
            <h4>{jobs[activeIndex].company}</h4>
            <p><span style={{ paddingRight: '20px' }}>{jobs[activeIndex].startDate} - {jobs[activeIndex].endDate}</span> {jobs[activeIndex].location}</p>
            {descItems}
          </div>
        </div>

        <div className="row justify-content-center" style={{ marginTop: '3em' }}>
          <div className="col-lg-10">
            <ExperienceTimeline {...{ activeIndex: activeIndex, changeActiveIndex: (index) => setActiveIndex(index), jobs: jobs }} />
          </div>
        </div>
      </div>

    </section>
  );
}

export default ExperienceSection;
