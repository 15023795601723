import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import './slide-menu.scss';

function SlideMenu(props) {
  let { open, toggleMenu } = props;

  useEffect(() => {
    let slideM = document.getElementById('slide-menu');
    let filter = document.getElementById('screen-filter');

    if (open) {
      filter.classList.replace('screen-filter-close', 'screen-filter-open');
      slideM.className = 'slide-menu-open';
    } else {
      filter.classList.replace('screen-filter-open', 'screen-filter-close');
      slideM.className = 'slide-menu-close';
    }
  }, [open]);

  return (
    <>
      <div id="screen-filter" className="screen-filter-close" onClick={toggleMenu}></div>
      <section id="slide-menu">
        {/* <Link to="/3d-designs" onClick={toggleMenu} alt="3D Printing Projects" className="menu-item" id="menu-top-item">3D Printing Projects</Link> */}
        <HashLink smooth to="/#contact" onClick={toggleMenu} alt="Contact" className="menu-item">Contact</HashLink>
        <div className="menu-title">
          Live Projects
      </div>
        <a href="http://domenickdibiase.com/bingo" target="_blank" onClick={toggleMenu} rel="noopener noreferrer"
          alt="Contact">
          <div className="menu-item">
            Bingo&nbsp;&nbsp;<i className="fas fa-external-link-alt" style={{ fontSize: '.8rem' }}></i>
          </div>
        </a>
        <a href="http://dayarray.com" target="_blank" onClick={toggleMenu} rel="noopener noreferrer" alt="Contact">
          <div className="menu-item">
            DayArray&nbsp;&nbsp;<i className="fas fa-external-link-alt" style={{ fontSize: '.8rem' }}></i>
          </div>
        </a>
      </section>
    </>
  );
}

export default SlideMenu;
