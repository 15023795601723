import React from "react";
import "./biographySection.scss";
import PortraitImg from "../../images/portrait-min.png";

const recentTechItem = (item) => {
  return (
    <React.Fragment key={item}>
      <i className="fas fa-angle-right"></i> {item}
      <br />
    </React.Fragment>
  );
};

const linkIcon = (refLink, iconClassNames) => {
  return (
    <a href={refLink} target="_blank" rel="noopener noreferrer" className="profile-link icon-sm">
      <i className={iconClassNames}></i>
    </a>
  );
};

function BiographySection() {
  const recentTechListLeft = ["React", "Node.js", "MongoDB"];
  const recentTechListRight = ["Angular", "HTML & (S)CSS", "MySQL"];

  return (
    <section id="biography" className="section">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-3 center mgn-bt-2 txt-center">
            <img src={PortraitImg} id="portrait-img" alt="Self portrait" />
            <div id="links" style={{ marginTop: "3rem" }}>
              {linkIcon("https://github.com/Domenick97", "fab fa-github")}
              {linkIcon("https://linkedin.com/in/domenickd", "fab fa-linkedin-in")}
              {linkIcon("/docs/resume.pdf", "far fa-file-alt")}
            </div>
          </div>

          <div className="col-md-8 wrapper preAnimation animateBlock right">
            <h4>Hi, I'm Domenick.</h4>
            <p style={{ color: "#EBEBEB" }}>
              I'm a Full Stack developer with an interest in web design and an eagerness to learn
              new things. Check out some of my top side pojects below or take a look at my{" "}
              <a
                href="https://github.com/Domenick97"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#9CC2D6" }}
              >
                GitHub
              </a>{" "}
              page to see what I am currently working on.
            </p>
            <br />
            <p style={{ color: "#EBEBEB" }}>
              I graduated from{" "}
              <span style={{ color: "white", fontWeight: "bold" }}>
                North Carolina State University
              </span>{" "}
              with a degree in computer science. Since then, I have been working at IBM as a
              software developer focusing on the frontend development and user experience of
              internal web applications.
            </p>
            <br />
            <br />
            <br />
            <p style={{ color: "white" }}>
              Heres some of the technologies I have been using recently:
            </p>
            <div id="recent-tech">
              <div id="tech-col-left">{recentTechListLeft.map((item) => recentTechItem(item))}</div>
              <div id="tech-col-right">
                {recentTechListRight.map((item) => recentTechItem(item))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BiographySection;
