import { useEffect } from 'react';
import './header.scss';

const HambugerMenu = (props) => {
  let { open, toggleMenu } = props;

  useEffect(() => {
    var bars = [document.getElementById("d1"),
    document.getElementById("d2"),
    document.getElementById("d3")];

    var barToggleOn = [['6px', '4px'],
    ['2px', '0px'],
    ['10px', '8px']];

    var barToggleOff = [['10px', '8px'],
    ['2px', '0px'],
    ['6px', '4px']];

    if (!open) {
      bars.forEach((bar, index) => {
        bar.style.right = '0px';
        setTimeout(() => {
          bar.style.left = barToggleOff[index][0];
        }, 200);
        setTimeout(() => {
          bar.style.left = barToggleOff[index][1];
        }, 400);
      });
    } else {
      bars.forEach((bar, index) => {
        bar.style.left = '0px';
        setTimeout(() => {
          bar.style.right = barToggleOn[index][0];
        }, 200);
        setTimeout(() => {
          bar.style.right = barToggleOn[index][1];
        }, 400);
      });
    }
  }, [open]);

  return (
    <div id="hamburger-menu" onClick={toggleMenu}>
      <div id="hamburger-icon">
        <div id="d1"></div>
        <div id='d2'></div>
        <div id='d3'></div>
      </div>
    </div>
  );
}

export default HambugerMenu;
