import React, { useEffect, useState } from "react";
import "./footer.scss";
import Logo from "../../images/d-logo.png";
import PrivacyPolicyModal from "../privacyPolicyModal/privacyPolicyModal";

function Footer() {
  let [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    // Updates the date of the copyright to the current year
    document.getElementById("cr-date").innerText = new Date().getFullYear();
  }, []);

  return (
    <>
      {modalOpen ? <PrivacyPolicyModal toggleModal={() => setModalOpen(!modalOpen)} /> : ""}
      <footer id="footer">
        <div id="footer-columns">
          <div id="footer-logo">
            <img src={Logo} alt="Logo" />
          </div>
          <div id="footer-info">
            <div>
              <a href="/docs/resume.pdf" target="_blank" rel="noopener noreferrer">
                Resume
              </a>
            </div>
            <div>
              <a href="mailto:domenickdibiase@gmail.com">domenickdibiase@gmail.com</a>
            </div>
            <div>
              <span className="mock-link" onClick={() => setModalOpen(!modalOpen)}>
                Privacy Policy
              </span>
            </div>
          </div>
          <div id="footer-follow">
            <div>
              <a href="https://linkedin.com/in/domenickd" target="_blank" rel="noopener noreferrer">
                LinkedIn
              </a>
            </div>
            <div>
              <a href="https://github.com/Domenick97" target="_blank" rel="noopener noreferrer">
                Github
              </a>
            </div>
            <div>
              <a
                href="https://stackoverflow.com/users/9525603/domenick?tab=profile"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stack Overflow
              </a>
            </div>
          </div>
        </div>
        <br />
        <div id="copywrite">
          Copyright &copy; 2017 - <span id="cr-date"></span>
        </div>
      </footer>
    </>
  );
}

export default Footer;
