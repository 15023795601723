import React from "react";
import "../../style/animation.scss";
import "./projectsSection.scss";
import TriRankImg from "../../images/Projects/TriRank.png";
import TidalBoard from "../../images/Projects/TidalBoard.png";

const MockBrowserShell = (props) => {
  const { urlText, children } = props;
  return (
    <div className="project-tile">
      <div className="mock-browser-frame-top">
        <div className="mock-browser-btn-red"> </div>
        <div className="mock-browser-btn-yellow"> </div>
        <div className="mock-browser-btn-green"> </div>
        <div className="mock-browser-url-input">{urlText}</div>
      </div>
      <div className="project-img">{children}</div>
    </div>
  );
};

const ProjectDetails = (props) => {
  const { githubLink, sourceLink, title, techStack, children } = props;

  return (
    <div className="col-lg-6 align-self-center wrapper preAnimation animateBlock point">
      <div className="project-desc">
        <div className="project-title">{title}</div>
        {children}
        <div className="project-links">
          {githubLink && (
            <a href={githubLink} target="_blank" rel="noopener noreferrer">
              <i className="fab fa-github github-icon"></i>
            </a>
          )}
          <a href={sourceLink} target="_blank" rel="noopener noreferrer">
            <i className="fas fa-external-link-alt site-icon"></i>
          </a>
        </div>
      </div>

      <div className="project-languages">{techStack}</div>
    </div>
  );
};

function ProjectsSection() {
  return (
    <section id="projects" className="section">
      <div className="container">
        <div className="row title" id="projects-section-title">
          <h2>Projects</h2>
        </div>

        <div className="row justify-content-start" style={{ marginTop: "2rem" }}>
          <div className="col-lg-6 wrapper preAnimation animateBlock left">
            <MockBrowserShell urlText="https://tidalboard.com">
              <a
                href="https://tidalboard.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={TidalBoard} alt="Job Journy" />
              </a>
            </MockBrowserShell>
          </div>

          <ProjectDetails
            sourceLink="https://tidalboard.com"
            title="Tidal Board"
            techStack="React, NodeJS, Redux, MongoDB, AWS"
          >
            <p style={{ marginBottom: "10px" }}>
              Manage and track job applications during the job hunt
            </p>
            <p style={{ fontStyle: "italic", color: "#626262" }}>Work in progress</p>
          </ProjectDetails>
        </div>

        <div className="row justify-content-end project-row-reverse">
          <ProjectDetails
            sourceLink="https://trirank.com"
            title="Tri Rank"
            techStack="React, NodeJS, MongoDB, AWS"
          >
            <p style={{ marginBottom: "10px" }}>
              View and compare triathlon race results and statistics
            </p>
            <p style={{ fontStyle: "italic", color: "#626262" }}>Work in progress</p>
          </ProjectDetails>

          <div className="col-lg-6 wrapper preAnimation animateBlock right">
            <MockBrowserShell urlText="https://trirank.com">
              <a href="https://trirank.com" target="_blank" rel="noopener noreferrer">
                <img src={TriRankImg} alt="Tri Rank" />
              </a>
            </MockBrowserShell>
          </div>
        </div>

        {/* <div className="row justify-content-start" style={{ marginTop: "4rem" }}>
          <div className="col-lg-6 wrapper preAnimation animateBlock left">
            <MockBrowserShell urlText="https://domenick97.github.io/Bingo/home">
              <a
                href="https://domenick97.github.io/Bingo/home"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={BingoImg} alt="Bingo" />
              </a>
            </MockBrowserShell>
          </div>

          <ProjectDetails
            githubLink="https://github.com/Domenick97/Bingo"
            sourceLink="https://domenick97.github.io/Bingo/home"
            title="Bingo"
            techStack="Angular, HTML, CSS"
          >
            <p>Play Bingo against a computer</p>
          </ProjectDetails>
        </div> */}
      </div>
    </section>
  );
}

export default ProjectsSection;
