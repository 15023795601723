import './modeling.scss';
import { Link } from 'react-router-dom';
import DogImg from '../../images/3d/dog/dog-real.JPG';
import TableImg from '../../images/3d/table/table-open.PNG';
import EaringsImg from '../../images/3d/earings/earings-real.JPG';
import BridgeImg from '../../images/3d/bridge/bridge-real.JPG';
import BoxImg from '../../images/3d/box/box1.png';
import StemImg from '../../images/3d/stem-cycle/stem1.png';
import { useState } from 'react';
import { HashLink } from 'react-router-hash-link';


const DangerModal = (props) => {
  let { setShowModal } = props;

  return (
    <>
      <div className="screen-blocker"></div>
      <div id="danger-modal">
        <div className="modal-button-text">
          Warning, <br />this part of the site is currently under construction with the completion estimated around the end of Jan 2021.
          <br /><br />
          <em>Check back later for a completed modeling portfolio or&nbsp;
          <HashLink smooth to="/#contact" alt="Contact">Contact Me</HashLink>&nbsp;to be notified of future updates.</em>
        </div>
        <div className="modal-button-row">
          <Link to="/" className="danger-modal-button">
            Back
        </Link>
          <div className="danger-modal-button" onClick={setShowModal}>
            Take me to the chaos!
          </div>
        </div>
      </div>
    </>
  )
}

const Modeling = () => {
  let [showModal, setShowModal] = useState(true);

  return (
    <>
      <div className="plain-bg">
        <section style={{ width: '90%', margin: 'auto', marginTop: '55px', paddingTop: '30px' }}>
          <h2>BackGround</h2>
          <p style={{ paddingLeft: '10px' }}>In high school, I took two drafting and an advanced studies in drafting
        classes.<br /><span className="color-blue">Drafting I</span> - I learned how to sketch by hand and use drafting
        tools. Auto CAD was also introduced during this class and we used it to make blue prints of different
        objects.<br /><span className="color-blue">Drafting II</span> - I learned how to apply my design skills from Drafting
        I to create 3D models using Autodesk Inventor.<br /><span className="color-blue">Advanced Studies</span> - I
        continued 3D modeling and and designing past the standard school courses. In this class I was given topics that
        I had to research, come up with a design for, model, then either 3D print or animate.</p>
        </section>

        <section style={{ width: '100%', margin: 'auto', marginTop: '40px' }}>
          <h2 style={{ width: '90%', margin: 'auto' }}>Projects</h2>
          <div style={{ display: 'flex', justifyContent: 'center', flexFlow: 'wrap' }}>

            <Link to="/3d-designs/dog-and-bone" className="model-tile">
              <img src={DogImg} alt="3D printed dog with a bone" />
            </Link>

            <Link to="/3d-designs/table" className="model-tile">
              <img src={TableImg} alt="3D model of a modular table" />
            </Link>

            <Link to="/3d-designs/earings" className="model-tile">
              <img src={EaringsImg} alt="3D printed NC State earings" />
            </Link>

            <Link to="/3d-designs/bridge" className="model-tile">
              <img src={BridgeImg} alt="3D printed scale model of Surf City Swing bridge" />
            </Link>

            <Link to="/3d-designs/box" className="model-tile">
              <img src={BoxImg} alt="3D model of custom side table box" />
            </Link>

            <Link to="/3d-designs/stem-cycle" className="model-tile">
              <img src={StemImg} alt="3D model of a STEM cycle prototype" />
            </Link>
          </div>
          {/* <p>Skate Board</p>
          <p>3D Printer accessories</p>
          <p>Picture Frames</p>
          <p>SD Card Holder</p> */}
        </section>

        <section style={{ width: '90%', margin: 'auto', paddingTop: '55px', paddingBottom: '30px' }}>
          <h2>Thingiverse Objects I Printed</h2>
          <h5>Surfboard fin: <a href="https://www.thingiverse.com/thing:156815/#files">Thingiverse link</a></h5>
          <p style={{ marginLeft: '20px', marginRight: '20px' }}>I successfully printed the fin I found on the Thingiverse website
          with a 25% fill. The product printed very nice with only a few of the layers towards the top overhang shifting
          slightly. I believe this was due to the lack of supports and how the fin would wiggle slightly as the base
          moved. When attempting to put the fin in an actual surfboard, it could not handle the pressure required to snap
          it into place and broke at the base. I would like to print this object again and try a 80% fill to give it more
          strength at the base.
      </p>
          <h5>Battery dispencer: <a href="https://www.thingiverse.com/thing:2145153">Thingiverse link</a></h5>
          <p style={{ marginLeft: '20px', marginRight: '20px' }}>One of my most used prints, the AAA battery dispencer I found on
          Thiniverse printed perfectly. I was initially worried that the oval shape in the center would cause issues when
          it was time for the overhang but my printer had no issues and there were no drooping layers. It is a great way
          to store AAA batteries on your desk. Only thing I would change about the design is the back platform used for
          support. I believe it can be reduced or even eleminated and maintain its balance. This would make it easier to
          place up against a wall and not take up as much room.
      </p>
        </section>

      </div >
      {showModal ? <DangerModal setShowModal={() => setShowModal(!showModal)} /> : ''}
    </>
  )
}

export default Modeling;


