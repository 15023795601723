import './modelProfile.scss';

const ModelProfile = () => {
  return (
    <>
      Dog
      <iframe allowfullscreen webkitallowfullscreen width="640" height="480" frameborder="0" seamless src="https://p3d.in/e/FxqAd"></iframe>

        Modular Table Design
      <iframe allowfullscreen webkitallowfullscreen width="560" height="315" frameborder="0" seamless
        src="https://p3d.in/e/Y1tUV"></iframe>

      Table YoutTube
      <iframe width="560" height="315" src="https://www.youtube.com/embed/xgDOOZDKfv8" frameborder="0"
        allow="autoplay; encrypted-media" allowfullscreen></iframe>

      Earings
      <iframe allowfullscreen webkitallowfullscreen width="640" height="480" frameborder="0" box-shadow="0" seamless src="https://p3d.in/e/BG6Oo"></iframe>
    </>
  )
};

export default ModelProfile;