import { useState } from 'react';
import Header from './header/header';
import SlideMenu from './slide-menu';


function UIFrame() {
  let [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <Header menuOpen={menuOpen} toggleMenu={() => setMenuOpen(!menuOpen)} />
      <SlideMenu open={menuOpen} toggleMenu={() => setMenuOpen(!menuOpen)} />
    </>
  );
}

export default UIFrame;
