
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/fonts.scss';
import './App.scss';
import UIFrame from './components/ui-frame/ui-frame';
import MainPage from './views/mainPage/mainPage';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Modeling from './views/modeling/modeling';
import ModelProfile from './views/modeling/modelProfile/modelProfile';

function App() {
  return (
    <Router>
      <UIFrame />
      <Switch>
        <Route exact path="/">
          <MainPage />
        </Route>
        <Route exact path="/3d-designs">
          <Modeling />
        </Route>
        <Route path="/3d-designs/:model">
          <ModelProfile />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
