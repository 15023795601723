import './privacyPolicyModal.scss';
import ExitImg from '../../images/times-solid.svg';
import React, { useState } from 'react';



const PrivacyPolicyModal = (props) => {
  let { toggleModal } = props;
  let boxWidth = 550;
  let [boxHeight, setBoxHeight] = useState(600);
  let [boxLeft, setBoxLeft] = useState((window.innerWidth - boxWidth) / 2);
  let [boxTop, setBoxTop] = useState((window.innerHeight - boxHeight) / 2);

  window.addEventListener('resize', () => {
    if (window.innerHeight < 700) {
      let height = window.innerHeight - 50;
      let top = (window.innerHeight - height) / 2;
      setBoxTop(top < 40 ? 40 : top);
      setBoxHeight(height);
    } else {
      let top = (window.innerHeight - boxHeight) / 2;
      setBoxTop(top < 10 ? 10 : top);
      setBoxHeight(600);
    }

    var left = (window.innerWidth - boxWidth) / 2;
    setBoxLeft(left < 10 ? 10 : left);
  });

  return (
    <>
      <div className="modal-screen-film-open" onClick={toggleModal}></div>
      <div id="pp-modal-body" style={{ top: boxTop + "px", left: boxLeft + 'px', height: boxHeight + 'px' }}>
        <img src={ExitImg} id="exit-icon" alt="exit modal" onClick={toggleModal} />
        <div id="policy">
          <p className="prompt">Privacy Policy</p>
          <p>Like many other website developers, I use Google Analytics on domenickdibiase.com. Google Analytics is software that fetchs data about my visitors (you). With it I use the data to improve my site to better suite my vistitors.</p>
          <p className="prompt">What does Google Analytics record?</p>
          <p>Google Analytics records what website you came from to get here, how long you stay here for, what kind of computer you're using, and much more anonymous infromation. With this, all of my activity falls within the bounds of the Google Analytics Terms of Service.</p>
          <p className="prompt">Want to opt out of tracking?</p>
          <p>Refer to Google's Privacy and Terms page to learn more about how to opt out of Google's advertising tracking cookie.</p>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicyModal;