import './header.scss';
import Logo from '../../../images/d-logo.png';
import HamburgerMenu from './hamburgerMenu';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Header(props) {
  let { menuOpen, toggleMenu } = props;

  return (
    <header>
      <div className="container-fluid">
        <Link to="/" alt="Domenick DiBiase Home" id="header-name"><img src={Logo} alt="Logo" /></Link>
        <nav>
          <HashLink smooth to="/#contact" alt="Contact" id="header-contact">Contact Me</HashLink>
          <HamburgerMenu open={menuOpen} toggleMenu={toggleMenu} />
        </nav>
      </div>
    </header >
  );
}

export default Header;
