import React from "react";
import "./contactSection.scss";

const ContactSection = () => {
  return (
    <section id="contact" className="section">
      <div className="container">
        <div className="row title title-bottom" id="contact-section-title">
          <h2>Contact Me</h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <p>
              If you would like to contact me directly feel free to send me an email at{" "}
              <a href="mailto:domenickdibiase@gmail.com">domenickdibiase@gmail.com</a>&nbsp; or fill
              out the form below.
            </p>
            <form action="https://formspree.io/domenickdibiase@gmail.com" method="POST">
              <input type="hidden" name="_next" value="http://domenickdibiase.com" />
              <div>
                <label>Full Name:</label>
                <br />
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                />
              </div>
              <div>
                <label>Email Address:</label>
                <br />
                <input
                  className="form-control"
                  type="email"
                  name="_replyto"
                  id="email"
                  placeholder="Email Address"
                />
              </div>
              <div>
                <label>Subject:</label>
                <br />
                <input
                  className="form-control"
                  type="text"
                  name="subject"
                  id="message-subject"
                  placeholder="Subject"
                />
              </div>
              <div>
                <label>Message:</label>
                <br />
                <textarea
                  className="form-control"
                  placeholder="Message"
                  id="message-body"
                  name="body"
                ></textarea>
              </div>
              <div>
                <button type="submit" value="Send">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
